import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { array, object, string } from 'yup'
import React, { useState } from 'react'
import ButtonSubmit from '../common/ButtonSubmit'
import { estimatedEmployeeCountOptions, officeLocationRelationshipTypeOptions } from './utils'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
const emailDomainRegex = /^\b((?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b$/

const createSchema = () =>
  object().shape({
    name: string().required("Please enter the office's name."),
    emailDomain: string()
      .matches(emailDomainRegex, 'Invalid email domain')
      .required("Please enter the office's email domain."),
    externalManagerEmails: array().of(
      string().matches(emailRegex, ({ value }) => `Invalid email: ${value}`),
    ),
  })

const LocationManagerAddOfficeDialog = ({
  handleAddOffice,
  isCreatingOffice,
  errorCreatingOffice,
  clearOfficeErrors,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [openAddOfficeDialog, setOpenAddOfficeDialog] = useState(false)

  const handleCloseAddOfficeDialog = () => {
    setOpenAddOfficeDialog(false)
    clearOfficeErrors()
  }

  return (
    <>
      <Box ml={2}>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '40px',
            whiteSpace: 'nowrap',
            padding: '0 16px',
          }}
          onClick={() => setOpenAddOfficeDialog(true)}
        >
          Add Office
        </Button>
      </Box>
      {openAddOfficeDialog && (
        <Dialog open fullWidth onClose={handleCloseAddOfficeDialog}>
          <Formik
            initialValues={{
              estimatedEmployeeCount: '',
              officeLocationRelationshipType: 'TENANT',
              name: '',
              emailDomain: '',
              externalManagerEmails: [],
            }}
            onSubmit={handleAddOffice}
            validationSchema={createSchema()}
          >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
              <Form>
                <DialogContent>
                  {/* Name Field */}
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: '500' }}>Name:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <TextField
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          fullWidth
                          id="name"
                          name="name"
                          placeholder="Name"
                          margin="dense"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                          inputProps={{ maxLength: 60 }}
                        />
                      </Grid>
                      <Grid item style={{ marginTop: '-0.5em' }}>
                        <Typography variant="caption" color="textSecondary">
                          {values.name.length}/60 characters
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Email Domain Field */}
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: '500' }}>Email Domain:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container>
                      <Grid item>
                        <TextField
                          error={touched.emailDomain && Boolean(errors.emailDomain)}
                          helperText={touched.emailDomain && errors.emailDomain}
                          fullWidth
                          id="emailDomain"
                          name="emailDomain"
                          placeholder="Email Domain"
                          margin="dense"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.emailDomain}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Size Dropdown */}
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: '500' }}>Size:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl fullWidth margin="dense">
                      <Select
                        id="estimatedEmployeeCount"
                        name="estimatedEmployeeCount"
                        value={values.estimatedEmployeeCount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        displayEmpty
                      >
                        {Object.keys(estimatedEmployeeCountOptions).map(key => (
                          <MenuItem key={key} value={key}>
                            {estimatedEmployeeCountOptions[key]} people
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Type Dropdown */}
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: '500' }}>Type:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl fullWidth margin="dense">
                      <Select
                        id="officeLocationRelationshipType"
                        name="officeLocationRelationshipType"
                        value={values.officeLocationRelationshipType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                      >
                        {Object.keys(officeLocationRelationshipTypeOptions).map(key => (
                          <MenuItem key={key} value={key}>
                            {officeLocationRelationshipTypeOptions[key]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* External Managers Field */}
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: '500' }}>Admins:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          border="1px solid #ccc"
                          borderRadius="4px"
                          padding="8px"
                        >
                          {/* Dynamic chips for values.externalManagerEmails */}
                          {values.externalManagerEmails.map((email, index) => (
                            <Chip
                              key={index}
                              label={email}
                              onDelete={() => {
                                const updatedEmails = values.externalManagerEmails.filter(
                                  e => e !== email,
                                )
                                setFieldValue('externalManagerEmails', updatedEmails)
                              }}
                              style={{ margin: '4px' }}
                            />
                          ))}

                          <TextField
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Backspace' && !inputValue) {
                                const updatedEmails = values.externalManagerEmails.slice(0, -1)
                                setFieldValue('externalManagerEmails', updatedEmails)
                              }
                            }}
                            onKeyPress={e => {
                              if (e.key === ',' || e.key === 'Enter') {
                                e.preventDefault()
                                const trimmedInput = inputValue.trim()
                                if (trimmedInput) {
                                  setFieldValue('externalManagerEmails', [
                                    ...values.externalManagerEmails,
                                    trimmedInput,
                                  ])
                                  setInputValue('')
                                }
                              }
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{ flex: 1, minWidth: '120px' }}
                          />
                        </Box>
                      </Grid>

                      <Grid item style={{ marginTop: '-0.5em' }}>
                        <Typography variant="caption" color="textSecondary">
                          Type email & press enter or a comma
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {errorCreatingOffice && (
                    <Typography color="error">{errorCreatingOffice}</Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button variant="text" onClick={handleCloseAddOfficeDialog}>
                    Cancel
                  </Button>
                  <ButtonSubmit variant="text" submitting={isCreatingOffice}>
                    Save
                  </ButtonSubmit>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  )
}

export default LocationManagerAddOfficeDialog
