import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { array, object, string } from 'yup'
import React, { useState } from 'react'
import ButtonSubmit from '../common/ButtonSubmit'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/

const createSchema = () =>
  object().shape({
    usernames: array().of(string().matches(emailRegex, ({ value }) => `Invalid email: ${value}`)),
  })

const AddManyLocationManagersDialog = ({
  isCreatingManyLocationManagers,
  errorCreatingManyLocationManagers,
  handleAddManyLocationManagers,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [openLocationManager, setOpenAddLocationManager] = useState(false)

  const handleCloseAddLocationManagerDialog = () => {
    setOpenAddLocationManager(false)
  }

  return (
    <>
      <Box ml={2}>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '40px',
            whiteSpace: 'nowrap',
            padding: '0 16px',
          }}
          onClick={() => setOpenAddLocationManager(true)}
        >
          Add Managers
        </Button>
      </Box>
      {openLocationManager && (
        <Dialog open fullWidth onClose={handleCloseAddLocationManagerDialog}>
          <Formik
            initialValues={{
              usernames: [],
            }}
            onSubmit={handleAddManyLocationManagers}
            validationSchema={createSchema()}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <DialogContent>
                  <Grid>
                    <Typography style={{ fontWeight: '500' }}>Location Managers:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          border="1px solid #ccc"
                          borderRadius="4px"
                          padding="8px"
                        >
                          {/* Dynamic chips for values.usernames */}
                          {values.usernames.map((email, index) => (
                            <Chip
                              key={index}
                              label={email}
                              onDelete={() => {
                                const updatedEmails = values.usernames.filter(e => e !== email)
                                setFieldValue('usernames', updatedEmails)
                              }}
                              style={{ margin: '4px' }}
                            />
                          ))}

                          <TextField
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Backspace' && !inputValue) {
                                const updatedEmails = values.usernames.slice(0, -1)
                                setFieldValue('usernames', updatedEmails)
                              }
                            }}
                            onKeyPress={e => {
                              if (e.key === ',' || e.key === 'Enter') {
                                e.preventDefault()
                                const trimmedInput = inputValue.trim()
                                if (trimmedInput) {
                                  setFieldValue('usernames', [...values.usernames, trimmedInput])
                                  setInputValue('')
                                }
                              }
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{ flex: 1, minWidth: '120px' }}
                          />
                        </Box>
                      </Grid>

                      <Grid item style={{ marginTop: '-0.5em' }}>
                        <Typography variant="caption" color="textSecondary">
                          Type email & press enter or a comma
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {errorCreatingManyLocationManagers && (
                    <Typography color="error">{errorCreatingManyLocationManagers}</Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button variant="text" onClick={handleCloseAddLocationManagerDialog}>
                    Cancel
                  </Button>
                  <ButtonSubmit variant="text" submitting={isCreatingManyLocationManagers}>
                    Save
                  </ButtonSubmit>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  )
}

export default AddManyLocationManagersDialog
