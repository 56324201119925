import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { loadProfileStart } from './redux/modules/profile'
import { selectUserLocationId } from './redux/modules/selectors'
import { currentUserStart } from './redux/modules/user'
import { loadLocationStart } from './redux/modules/location'
import { loadTeamStart, setCanLoadTeam } from './redux/modules/team'
import { loadUserTeamStart } from './redux/modules/userTeam'
import { selectUserTeam } from './redux/selectors/userTeamSelectors'
import { loadAccountSubscriptionPriceStart } from './redux/modules/subscriptions'
import { selectTeam } from './redux/selectors/teamSelectors'
import { loadPreferredOfficesStart } from './redux/modules/offices'
import { loadLocationUserManagesStart } from './redux/modules/locationManager'

//this component loads globally necessary data objects (and objects that need to be known on load, for various reasons)
const GlobalDataLoader = ({
  children,
  match: {
    params: { locationId: stringLocationId, accountId: stringAccountId },
  },
  currentUser = {},
  isCurrentUserLoading,
  currentUserStart,
  loadProfileStart,
  loadLocationStart,
  isAuthenticated,
  userInitialized,
  profileInitialized,
  locationInitialized,
  userTeam,
  userTeamInitialized,
  teamInitialized,
  setCanLoadTeam,
  canLoadTeam,
  loadUserTeamStart,
  loadTeamStart,
  loadAccountSubscriptionPriceStart,
  team,
  loadPreferredOfficesStart,
  locationUserManagesInitialized,
  loadLocationUserManagesStart,
}) => {
  const locationId = Number(stringLocationId)
  const accountId = Number(stringAccountId)
  const canLoadLocationUserManages = Boolean(locationId)

  useEffect(() => {
    currentUserStart()
  }, [currentUserStart])

  //load more stuff any time current user is updated
  useEffect(() => {
    if (currentUser.userId && userInitialized) {
      loadProfileStart()
      loadUserTeamStart()
    }
  }, [currentUser?.userId, userInitialized, loadProfileStart, loadUserTeamStart])

  useEffect(() => {
    if (currentUser.userId && userInitialized) {
      loadPreferredOfficesStart()
    }
  }, [currentUser?.userId, userInitialized, loadPreferredOfficesStart])

  useEffect(() => {
    if (canLoadLocationUserManages && currentUser.userId && userInitialized) {
      loadLocationUserManagesStart(locationId)
    }
  }, [
    locationId,
    canLoadLocationUserManages,
    currentUser?.userId,
    userInitialized,
    loadLocationUserManagesStart,
  ])

  useEffect(() => {
    if (locationId || (currentUser?.deliveryLocationId && userInitialized)) {
      loadLocationStart(locationId || currentUser.deliveryLocationId)
    }
  }, [locationId, currentUser?.deliveryLocationId, userInitialized, loadLocationStart])

  useEffect(() => {
    // TODO We won't need to load `team` in the global loader when/if we get the necessary information in the aggregated (`userTeam`) response
    if (accountId) {
      loadTeamStart(accountId)
    } else if (userTeam?.team?.manager) {
      loadTeamStart(userTeam?.team?.accountId)
    } else {
      setCanLoadTeam(false)
    }
  }, [accountId, loadTeamStart, userTeam?.team?.accountId, userTeam?.team?.manager])

  useEffect(() => {
    if (team) {
      loadAccountSubscriptionPriceStart()
    }
  }, [loadAccountSubscriptionPriceStart, team])

  const isInitializing =
    !userInitialized ||
    !profileInitialized ||
    !locationInitialized ||
    !userTeamInitialized ||
    (!teamInitialized && canLoadTeam) ||
    (!locationUserManagesInitialized && canLoadLocationUserManages)

  const loading = isCurrentUserLoading || (isAuthenticated && isInitializing)

  if (loading) {
    return <LoadingState />
  } else {
    return <>{children}</>
  }
}

const LoadingState = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    width="100%"
  >
    <Box maxWidth="200px" clone>
      <img alt="" src={`${process.env.REACT_APP_BASE_ORDER_URL}/images/logo-foodsby.png`} />
    </Box>
    <Box textAlign="left" width="55px" mt="25px">
      <Loader variant="subtitle2">
        Loading<span>.</span>
        <span>.</span>
        <span>.</span>
      </Loader>
    </Box>
  </Box>
)

const mapStateToProps = state => {
  const locationId = selectUserLocationId(state)
  const { isAuthenticated, isCurrentUserLoading, currentUser, userInitialized } = state.user
  const { profileInitialized, isLoadingProfile } = state.profile
  const { locationInitialized, isLocationLoading } = state.location
  const { teamInitialized, setCanLoadTeam, canLoadTeam } = state.team
  const team = selectTeam(state)
  const userTeam = selectUserTeam(state)
  const { userTeamInitialized } = state.userTeam
  const { locationUserManagesInitialized } = state.locationManager

  return {
    isAuthenticated,
    isCurrentUserLoading,
    isLoadingProfile,
    locationId,
    currentUser,
    isLocationLoading,
    userInitialized,
    profileInitialized,
    locationInitialized,
    userTeam,
    teamInitialized,
    setCanLoadTeam,
    canLoadTeam,
    userTeamInitialized,
    team,
    locationUserManagesInitialized,
  }
}
const mapDispatchToProps = {
  currentUserStart,
  loadProfileStart,
  loadLocationStart,
  loadUserTeamStart,
  loadTeamStart,
  loadAccountSubscriptionPriceStart,
  setCanLoadTeam,
  loadPreferredOfficesStart,
  loadLocationUserManagesStart,
}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalDataLoader)

const Loader = styled(Typography)`
  & span {
    font-size: 25px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  & span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`
