import { getCateringStoresForLocationApi } from '../../services/cateringStore'
import { createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const CATERING_SCHEDULE_SET = 'foodsby/cateringStore/CATERING_SCHEDULE_SET'

export const setCateringStores = createAsyncAction(CATERING_SCHEDULE_SET)

export const loadCateringStoresStart = (locationId, isHome) => {
  return dispatch => {
    dispatch(setCateringStores(getCateringStoresForLocationApi(locationId, isHome)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [PENDING(CATERING_SCHEDULE_SET)]: state => {
    return {
      ...state,
      isLoadingCateringStores: true,
    }
  },
  [FULFILLED(CATERING_SCHEDULE_SET)]: (state, action) => {
    return {
      ...state,
      cateringSchedule: action.payload ?? [],
      errorLoadingCateringStores: undefined,
      isLoadingCateringStores: false,
    }
  },
  [REJECTED(CATERING_SCHEDULE_SET)]: (state, action) => {
    return {
      ...state,
      cateringSchedule: [],
      errorLoadingCateringStores: action.payload,
      isLoadingCateringStores: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  cateringSchedule: [],
  isLoadingCateringStores: false,
  errorLoadingCateringStores: undefined,
}

export default function cateringStores(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
