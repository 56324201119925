import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import LocationStatus from './LocationStatus'
import CardLocationInformation from './CardLocationInformation'
import { formatDisplayAddress } from './utils'
import LocationManagers from './LocationManagers'
import CardCommonActions from './CardCommonActions'
import LocationManagerHeader from './LocationManagerHeader'
import ContactSupport from './ContactSupport'
import CardServiceInformation from './CardServiceInformation'
import CardLocation, { CardLocationContent, CardLocationTitle } from './CardLocation'

const LocationInformationTab = ({
  loading,
  locationUserManages,
  locationInfo,
  locationManagers,
  name,
  locationId,
  handleAddManyLocationManagers,
  isCreatingManyLocationManagers,
  errorCreatingManyLocationManagers,
}) => {
  if (loading) {
    return <SkeletonLoadingLocationInformationTab locationId={locationId} name={name} />
  }

  return (
    <>
      <LocationManagerHeader locationId={locationId} name={name} />
      <Box mt={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <LocationStatus locationStatus={locationUserManages.locationStatus} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <CardLocationInformation
              name={locationInfo.deliveryLocationName}
              address={formatDisplayAddress(
                locationInfo.street,
                locationInfo.city,
                locationInfo.state,
                locationInfo.zip,
              )}
              locationType={locationUserManages.locationType}
              populationCount={locationUserManages.populationCount}
            />
            <CardServiceInformation locationUserManages={locationUserManages} />
            <LocationManagers
              admins={locationManagers}
              handleAddManyLocationManagers={handleAddManyLocationManagers}
              isCreatingManyLocationManagers={isCreatingManyLocationManagers}
              errorCreatingManyLocationManagers={errorCreatingManyLocationManagers}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CardCommonActions locationId={locationUserManages.locationId} />
            <ContactSupport />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const SkeletonLoadingLocationInformationTab = ({ locationId, name }) => {
  return (
    <>
      <LocationManagerHeader locationId={locationId} name={name} />
      <Box mt={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <CardLocation>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'row'}
                marginBottom={'20px'}
                marginTop={'20px'}
              >
                <Typography style={{ fontWeight: 500, fontSize: '23px', paddingBottom: '20px' }}>
                  Location Status:{' '}
                </Typography>
                <Box marginLeft={'0.5em'} marginBottom={'1.5em'}>
                  <Skeleton width={'8em'} height={'4em'} />
                </Box>
              </Box>
            </CardLocation>
          </Grid>
          <Grid item xs={12} lg={8}>
            <CardLocation>
              <Typography variant="h6">Location Information</Typography>
              <Box marginTop={3} height={200} overflow="hidden">
                <Skeleton variant="rect" height="100%" />
              </Box>
            </CardLocation>
            <Box mb={2}>
              <CardLocation>
                <Typography variant="h6">Service Information</Typography>
                <Box marginTop={3} height={200} overflow="hidden">
                  <Skeleton variant="rect" height="100%" />
                </Box>
              </CardLocation>
            </Box>
            <Box mb={2}>
              <Paper variant="outlined" elevation={0}>
                <Box padding={4}>
                  <Typography variant="h6">Location Managers</Typography>
                  <Box marginTop={3} height={300} overflow="hidden">
                    <Skeleton variant="rect" height="100%" />
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardLocation>
              <CardLocationTitle>Common Actions</CardLocationTitle>
              <CardLocationContent>
                <Box marginTop={3} height={100} overflow="hidden">
                  <Skeleton variant="rect" height="100%" />
                </Box>
              </CardLocationContent>
            </CardLocation>
            <ContactSupport />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default LocationInformationTab
