import { call, put, select, take, race, takeEvery } from 'redux-saga/effects'

import {
  loadPastCateringOrdersByStoreId,
  loadPastOrdersByStoreId,
  reorderPastCateringOrder,
  reorderPastReserveOrder,
  reorderPastOrder,
} from '../../api/api'
import { normalizeErrorResponse } from '../../util/errorUtils'
import { FULFILLED } from '../utils'
import { loadCateringOrderCartStart, loadReserveOrderCartStart, loadOrderCartStart } from './cart'
import { DELIVERY_DROPOFF_SCHEDULE_SET } from './delivery'
import { apiSaga } from './sagas'
import { selectCurrentDeliveryDropoff, selectIsAuthenticated } from './selectors'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD_PAST_ORDERS = 'foodsby/pastorders/LOAD_PAST_ORDERS'
export const LOAD_PAST_CATERING_ORDERS = 'foodsby/pastorders/LOAD_PAST_CATERING_ORDERS'
export const LOAD_PAST_ORDERS_SUCCESS = 'foodsby/pastorders/LOAD_PAST_ORDERS_SUCCESS'
export const LOAD_PAST_ORDERS_FAILURE = 'foodsby/pastorders/LOAD_PAST_ORDERS_FAILURE'

export const ORDER_ITEM_ID_SELECTED = 'foodsby/pastorders/ORDER_ITEM_ID_SELECTED'

export const ADD_PAST_ORDER_TO_CART = 'foodsby/pastorders/ADD_PAST_ORDER_TO_CART'
export const ADD_PAST_ORDER_TO_CATERING_CART = 'foodsby/pastorders/ADD_PAST_ORDER_TO_CATERING_CART'
export const ADD_PAST_ORDER_TO_RESERVE_CART = 'foodsby/pastorders/ADD_PAST_ORDER_TO_RESERVE_CART'
export const ADD_PAST_ORDER_TO_CART_SUCCESS = 'foodsby/pastorders/ADD_PAST_ORDER_TO_CART_SUCCESS'
export const ADD_PAST_ORDER_TO_CART_FAILURE = 'foodsby/pastorders/ADD_PAST_ORDER_TO_CART_FAILURE'
export const ADD_PAST_ORDER_TO_CART_AND_CHECKOUT =
  'foodsby/pastorders/ADD_PAST_ORDER_TO_CART_AND_CHECKOUT'

export const loadPastOrdersStart = deliveryDropoffId => {
  return {
    payload: { deliveryDropoffId },
    type: LOAD_PAST_ORDERS,
  }
}

export const loadPastOrdersReserveStart = storeId => {
  return {
    payload: { storeId },
    type: LOAD_PAST_ORDERS,
  }
}

export const loadPastCateringOrdersStart = storeId => {
  return {
    payload: { storeId },
    type: LOAD_PAST_CATERING_ORDERS,
  }
}

export const loadPastOrdersSuccess = pastOrders => {
  return {
    payload: { pastOrders },
    type: LOAD_PAST_ORDERS_SUCCESS,
  }
}

export const loadPastOrdersFailure = error => {
  return {
    error,
    type: LOAD_PAST_ORDERS_FAILURE,
  }
}

export const addPastOrderToCart = pastOrder => {
  return {
    payload: { pastOrder },
    type: ADD_PAST_ORDER_TO_CART,
  }
}

export const addPastOrderToCateringCart = (storeId, locationId, pastOrder) => {
  return {
    payload: { storeId, locationId, pastOrder },
    type: ADD_PAST_ORDER_TO_CATERING_CART,
  }
}

export const addPastOrderToReserveCart = (storeId, locationId, pastOrder) => {
  return {
    payload: { storeId, locationId, pastOrder },
    type: ADD_PAST_ORDER_TO_RESERVE_CART,
  }
}

export const addPastOrderToCartAndCheckout = pastOrder => {
  return {
    payload: { pastOrder },
    type: ADD_PAST_ORDER_TO_CART_AND_CHECKOUT,
  }
}

export const addPastOrderToCartSuccess = cart => {
  return {
    payload: { cart },
    type: ADD_PAST_ORDER_TO_CART_SUCCESS,
  }
}

export const addPastOrderToCartFailure = error => {
  return {
    error,
    type: ADD_PAST_ORDER_TO_CART_FAILURE,
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [ADD_PAST_ORDER_TO_CART]: state => {
    return {
      ...state,
      isSavingPastOrderToCart: true,
    }
  },
  [ADD_PAST_ORDER_TO_CART_AND_CHECKOUT]: state => {
    return {
      ...state,
      isSavingPastOrderToCart: true,
    }
  },
  [ADD_PAST_ORDER_TO_CART_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(action.error.response, action.error.message)
    return {
      ...state,
      errorMessage: message,
      isSavingPastOrderToCart: false,
    }
  },
  [ADD_PAST_ORDER_TO_CART_SUCCESS]: state => {
    return {
      ...state,
      isSavingPastOrderToCart: false,
      selectedPastOrder: undefined,
    }
  },
  [LOAD_PAST_ORDERS]: state => {
    return {
      ...state,
      isPastOrderListLoading: true,
      pastOrders: undefined,
    }
  },
  [LOAD_PAST_CATERING_ORDERS]: state => {
    return {
      ...state,
      isPastOrderListLoading: true,
      pastOrders: undefined,
    }
  },
  [LOAD_PAST_ORDERS_FAILURE]: (state, action) => {
    const message = normalizeErrorResponse(action.error.response, action.error.message)
    return {
      ...state,
      errorMessage: message,
      isPastOrderListLoading: false,
    }
  },
  [LOAD_PAST_ORDERS_SUCCESS]: (state, action) => {
    const { pastOrders } = action.payload
    return {
      ...state,
      errorMessage: undefined,
      isPastOrderListLoading: false,
      pastOrders: pastOrders.pastOrders,
    }
  },
  [ORDER_ITEM_ID_SELECTED]: (state, action) => {
    const selectedOrderItemId = action.payload.orderItemId
    return {
      ...state,
      selectedOrderItemId,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  isPastOrderListLoading: true,
  pastOrders: [],
  isSavingPastOrderToCart: false,
}

export default function pastorder(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* handleAddPastOrderToCart(action) {
  const { pastOrder } = action.payload
  const { dropoffId } = yield select(selectCurrentDeliveryDropoff)

  try {
    yield call(reorderPastOrder, dropoffId, pastOrder.orderId)
    yield put(addPastOrderToCartSuccess())
    yield put(loadOrderCartStart(dropoffId))
  } catch (error) {
    yield put(addPastOrderToCartFailure(error))
  }
}

export function* watchAddPastOrderToCart() {
  yield takeEvery(ADD_PAST_ORDER_TO_CART, handleAddPastOrderToCart)
}

export function* watchAddPastOrderToCateringCart() {
  while (true) {
    const {
      payload: { storeId, locationId, pastOrder },
    } = yield take(ADD_PAST_ORDER_TO_CATERING_CART)
    yield call(
      apiSaga,
      reorderPastCateringOrder,
      [storeId, locationId, pastOrder.orderId],
      addPastOrderToCartSuccess,
      addPastOrderToCartFailure,
    )
  }
}

export function* watchAddPastOrderToReserveCart() {
  while (true) {
    const {
      payload: { storeId, locationId, pastOrder },
    } = yield take(ADD_PAST_ORDER_TO_RESERVE_CART)
    yield call(
      apiSaga,
      reorderPastReserveOrder,
      [storeId, locationId, pastOrder.orderId],
      addPastOrderToCartSuccess,
      addPastOrderToCartFailure,
    )
  }
}

export function* watchLoadPastOrders() {
  while (true) {
    yield take(LOAD_PAST_ORDERS)
    const { payload } = yield take(FULFILLED(DELIVERY_DROPOFF_SCHEDULE_SET))
    const isAuthenticated = yield select(selectIsAuthenticated)

    if (isAuthenticated) {
      try {
        yield call(
          apiSaga,
          loadPastOrdersByStoreId,
          [payload.storeId],
          loadPastOrdersSuccess,
          loadPastOrdersFailure,
        )
      } catch (ex) {
        yield put(loadPastOrdersFailure(ex))
      }
    }
  }
}

export function* watchAddPastOrderToCartSuccess() {
  while (true) {
    yield take(ADD_PAST_ORDER_TO_CART)
    const [response] = yield race([
      take(ADD_PAST_ORDER_TO_CART_SUCCESS),
      take(ADD_PAST_ORDER_TO_CART_FAILURE),
    ])
    if (response) {
      const { dropoffId } = yield select(selectCurrentDeliveryDropoff)
      yield put(loadOrderCartStart(dropoffId))
    }
  }
}

export function* watchAddPastOrderToCateringCartSuccess() {
  while (true) {
    const {
      payload: { storeId, locationId },
    } = yield take(ADD_PAST_ORDER_TO_CATERING_CART)
    const [response] = yield race([
      take(ADD_PAST_ORDER_TO_CART_SUCCESS),
      take(ADD_PAST_ORDER_TO_CART_FAILURE),
    ])
    if (response) {
      yield put(loadCateringOrderCartStart(storeId, locationId))
    }
  }
}

export function* watchAddPastOrderToReserveCartSuccess() {
  while (true) {
    const {
      payload: { storeId, locationId },
    } = yield take(ADD_PAST_ORDER_TO_RESERVE_CART)
    const [response] = yield race([
      take(ADD_PAST_ORDER_TO_CART_SUCCESS),
      take(ADD_PAST_ORDER_TO_CART_FAILURE),
    ])
    if (response) {
      yield put(loadReserveOrderCartStart(storeId, locationId))
    }
  }
}

export function* watchLoadPastCateringOrders() {
  while (true) {
    const response = yield take(LOAD_PAST_CATERING_ORDERS)
    const isAuthenticated = yield select(selectIsAuthenticated)

    if (isAuthenticated) {
      try {
        yield call(
          apiSaga,
          loadPastCateringOrdersByStoreId,
          [response.payload.storeId],
          loadPastOrdersSuccess,
          loadPastOrdersFailure,
        )
      } catch (ex) {
        yield put(loadPastOrdersFailure(ex))
      }
    }
  }
}

export function* watchLoadPastReserveOrders() {
  while (true) {
    const response = yield take(LOAD_PAST_ORDERS)
    const isAuthenticated = yield select(selectIsAuthenticated)

    if (isAuthenticated) {
      try {
        yield call(
          apiSaga,
          loadPastOrdersByStoreId,
          [response.payload.storeId],
          loadPastOrdersSuccess,
          loadPastOrdersFailure,
        )
      } catch (ex) {
        yield put(loadPastOrdersFailure(ex))
      }
    }
  }
}
