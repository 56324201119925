import { Container } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  loadLocationManagersStart,
  loadLocationManagerOfficesStart,
  loadLocationImagesStart,
  editOfficeStart,
  removeOfficeStart,
  setPageOffices,
  createOfficeStart,
  clearOfficeErrors,
  createManyLocationManagersStart,
} from '../redux/modules/locationManager'
import { selectCurrentLocationWithAddress } from '../redux/modules/selectors'
import NewPageLayout from '../layouts/NewPageLayout'
import {
  locationManagerDeliveryRoute,
  locationManagerOfficesRoute,
  locationManagerRoute,
  loginRoute,
} from '../routes/routes'
import LocationInformationTab from '../components/location-manager/LocationInformationTab'
import LocationOfficesTab from '../components/location-manager/LocationOfficesTab'
import LocationDeliveryTab from '../components/location-manager/LocationDeliveryTab'

class PageLocationManager extends Component {
  componentDidMount() {
    const { isAuthenticated } = this.props
    let {
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)
    if (isAuthenticated) {
      this.fetchLocationsManagers(locationId)
      this.fetchLocationsOffices(locationId)
      this.fetchLocationImages(locationId)
    }
    window && window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props
    let {
      match: {
        params: { locationId = 0 },
      },
    } = this.props
    locationId = Number(locationId)

    if (Number(prevProps.match.params.locationId) !== locationId) {
      this.fetchLocationsManagers(locationId)
      this.fetchLocationsOffices(locationId)
      this.fetchLocationImages(locationId)
    }
    if (prevProps.isAuthenticated === false && isAuthenticated === true) {
      this.fetchLocationsManagers(locationId)
      this.fetchLocationsOffices(locationId)
      this.fetchLocationImages(locationId)
    }
  }

  fetchLocationsManagers = locationId => {
    const { loadLocationManagersStart } = this.props
    loadLocationManagersStart(locationId)
  }

  fetchLocationsOffices = locationId => {
    const { loadLocationManagerOfficesStart } = this.props
    loadLocationManagerOfficesStart(locationId)
  }

  fetchLocationImages = locationId => {
    const { loadLocationImagesStart } = this.props
    loadLocationImagesStart(locationId)
  }

  handleEditOffice = values => {
    let {
      editOfficeStart,
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)
    const { officeId, ...rest } = values
    editOfficeStart(officeId, locationId, rest)
  }

  handleRemoveOffice = officeId => {
    let {
      removeOfficeStart,
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)
    removeOfficeStart(officeId, locationId)
  }

  handleAddOffice = values => {
    let {
      createOfficeStart,
      match: {
        params: { locationId },
      },
    } = this.props

    // Replace empty string
    const updatedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value === '' ? null : value]),
    )
    createOfficeStart(updatedValues, locationId)
  }

  handleAddManyLocationManagers = values => {
    let {
      createManyLocationManagersStart,
      match: {
        params: { locationId },
      },
    } = this.props

    const updatedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value === '' ? null : value]),
    )
    createManyLocationManagersStart(locationId, updatedValues)
  }

  render() {
    let {
      match: {
        params: { locationId },
      },
      isAuthenticated,
      location,
      locationUserManages,
      isLoadingLocationUserManages,
      isLocationLoading,
      locationManagers,
      locationOffices,
      isLoadingLocationOffices,
      isLoadingLocationManagers,
      locationImages,
      isLoadingLocationImages,
      isEditingOffice,
      errorEditingOffice,
      isRemovingOffice,
      errorRemovingOffice,
      pageOffices,
      setPageOffices,
      isCreatingOffice,
      errorCreatingOffice,
      clearOfficeErrors,
      isCreatingManyLocationManagers,
      errorCreatingManyLocationManagers,
    } = this.props
    locationId = Number(locationId)
    if (!isAuthenticated || (!isLoadingLocationUserManages && !locationUserManages)) {
      return <Redirect to={loginRoute.path} />
    }

    return (
      <NewPageLayout>
        <Container maxWidth="lg">
          <Switch>
            <Route path={locationManagerRoute.path} exact>
              <LocationInformationTab
                loading={
                  isLocationLoading || isLoadingLocationUserManages || isLoadingLocationManagers
                }
                locationUserManages={locationUserManages?.location}
                locationInfo={location}
                locationManagers={locationManagers}
                name={location?.deliveryLocationName}
                locationId={locationId}
                handleAddManyLocationManagers={this.handleAddManyLocationManagers}
                isCreatingManyLocationManagers={isCreatingManyLocationManagers}
                errorCreatingManyLocationManagers={errorCreatingManyLocationManagers}
              />
            </Route>

            <Route path={locationManagerOfficesRoute.path} exact>
              <LocationOfficesTab
                loadingTable={isLoadingLocationOffices}
                locationOffices={locationOffices}
                pageOffices={pageOffices}
                setPageOffices={setPageOffices}
                name={location?.deliveryLocationName}
                locationId={locationId}
                handleEditOffice={this.handleEditOffice}
                isEditingOffice={isEditingOffice}
                errorEditingOffice={errorEditingOffice}
                handleRemoveOffice={this.handleRemoveOffice}
                isRemovingOffice={isRemovingOffice}
                errorRemovingOffice={errorRemovingOffice}
                handleAddOffice={this.handleAddOffice}
                isCreatingOffice={isCreatingOffice}
                errorCreatingOffice={errorCreatingOffice}
                clearOfficeErrors={clearOfficeErrors}
              />
            </Route>

            <Route path={locationManagerDeliveryRoute.path} exact>
              <LocationDeliveryTab
                loading={isLoadingLocationUserManages || isLoadingLocationImages}
                locationUserManages={locationUserManages?.location}
                name={location?.deliveryLocationName}
                locationId={locationId}
                locationImages={locationImages}
              />
            </Route>
          </Switch>
        </Container>
      </NewPageLayout>
    )
  }
}

const mapStateToProps = state => {
  const {
    locationUserManages,
    isLoadingLocationUserManages,
    isLoadingLocationManagers,
    locationManagers,
    locationOffices,
    isLoadingLocationOffices,
    errorLoadingLocationOffices,
    locationImages,
    isLoadingLocationImages,
    isEditingOffice,
    errorEditingOffice,
    isRemovingOffice,
    errorRemovingOffice,
    pageOffices,
    isCreatingOffice,
    errorCreatingOffice,
    isCreatingManyLocationManagers,
    errorCreatingManyLocationManagers,
  } = state.locationManager
  const location = selectCurrentLocationWithAddress(state)
  const { isAuthenticated } = state.user
  const { isLocationLoading } = state.location

  return {
    isAuthenticated,
    location,
    isLocationLoading,
    locationUserManages,
    isLoadingLocationUserManages,
    isLoadingLocationManagers,
    locationManagers,
    locationOffices,
    isLoadingLocationOffices,
    errorLoadingLocationOffices,
    locationImages,
    isLoadingLocationImages,
    isEditingOffice,
    errorEditingOffice,
    isRemovingOffice,
    errorRemovingOffice,
    pageOffices,
    isCreatingOffice,
    errorCreatingOffice,
    isCreatingManyLocationManagers,
    errorCreatingManyLocationManagers,
  }
}

const mapDispatchToProps = {
  loadLocationManagersStart,
  loadLocationManagerOfficesStart,
  loadLocationImagesStart,
  editOfficeStart,
  removeOfficeStart,
  setPageOffices,
  createOfficeStart,
  createManyLocationManagersStart,
  clearOfficeErrors,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLocationManager)
