import 'react-day-picker/lib/style.css'
import { Card, Typography, Box, InputAdornment, TextField, MenuItem, Grid } from '@material-ui/core'
import React, { Component } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import DayPicker from 'react-day-picker'
import { format, parse } from 'date-fns'
import { withStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { CateringOrderSidebar } from '../components/place-order'
import {
  cancelEditingOrder,
  loadCateringOrderCartStart,
  loadEditOrder,
  addItemToCateringCartStart,
  editCateringOrderInCart,
  removeItemFromCateringOrderCartStart,
} from '../redux/modules/cart'
import { loadFavoriteStart, loadPopularMenuItems } from '../redux/modules/favorite'
import { clearMenuItem, loadCateringMenuStart, selectMenuItem } from '../redux/modules/menu'
import { addPastOrderToCateringCart, loadPastCateringOrdersStart } from '../redux/modules/pastorder'
import {
  getMenuInfoForNewPopularItems,
  getMenuInfoForPopularItems,
} from '../redux/modules/selectors'
import { pushAnalyticsEvent } from '../util/gtmUtils'
import AuthedComponent from '../components/common/AuthedComponent'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import { checkoutRoute, placeCateringOrderRoute } from '../routes/routes'
import { resetCheckoutError } from '../redux/modules/checkout'
import { createCateringDeliveryApi } from '../services/delivery'
import {
  selectFormattedCateringDeliveryDate,
  selectFormattedCateringDeliveryTime,
} from '../redux/selectors/deliverySelectors'
import { loadCateringStoreStart } from '../redux/modules/catering'
import RestaurantHoursAndNotice from '../components/common/RestaurantHoursAndNotice'
import { isOrderMinimumHit } from '../util/cateringUtils'
import ButtonSubmit from '../components/common/ButtonSubmit'
import { loadCateringDeliveryDateAndTimeStart, selectDateStart } from '../redux/modules/delivery'

const styles = () => ({
  dayPicker: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    marginTop: '3em',
    '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
      backgroundColor: '#db4c34',
      color: 'white',
    },
  },
  enterDelivery: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
  },
  timeField: {
    '& .MuiSelect-select': { minWidth: '11.3em' },
  },
})

export class PagePickCateringDeliveryDateTime extends Component {
  constructor(props) {
    super(props)
    this.props.clearMenuItem()
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      selectedDate: '',
      showDatePicker: false,
      time: '',
      errorCreatingCateringDelivery: '',
      checkoutErrorMessage: false,
      minimumErrorMessage: '',
      displayedMonth: new Date(),
    }
  }

  handleClose() {
    this.props.clearMenuItem()
    this.props.cancelEditingOrder()
  }

  getStoreIdAndLocationId = () => {
    let {
      match: {
        params: { storeId = 0, locationId = 0 },
      },
    } = this.props
    storeId = Number(storeId)
    locationId = Number(locationId)
    return { storeId, locationId }
  }

  handleAddItemsToCart = (menuItemId, itemValues, checkout) => {
    const { addItemToCateringCartStart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addItemToCateringCartStart(locationId, menuItemId, storeId, itemValues, checkout)
  }

  handleEditOrder = (orderItemId, menuItemId, itemValues, checkout) => {
    const { resetCheckoutError, editCateringOrderInCart } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    checkout && resetCheckoutError()

    editCateringOrderInCart(
      locationId,
      menuItemId,
      storeId,
      itemValues,
      orderItemId,
      true,
      checkout,
    )
  }

  convertTo24HourFormat = time12h => {
    const [timePart, modifier] = time12h.split(' ')
    let [hours, minutes] = timePart.split(':')
    if (hours === '12') {
      hours = '00'
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }
    return `${hours}:${minutes}:00`
  }

  handleNext = async () => {
    const { locationId, storeId } = this.getStoreIdAndLocationId()
    const { push, selectDateStart } = this.props
    const { selectedDate, time } = this.state

    if (!selectedDate || time === '') {
      return
    }
    const formattedDate = format(selectedDate, 'YYYY-MM-DD')
    const formattedTime = this.convertTo24HourFormat(time)

    try {
      const requestData = {
        date: formattedDate,
        time: formattedTime,
        locationId,
        storeId,
      }

      const response = await createCateringDeliveryApi(requestData)

      const deliveryDropoffId = response.deliveryDropoffIds[0]
      push(
        formatUrl(checkoutRoute.path, {
          locationId: locationId,
          dropoffId: deliveryDropoffId,
        }),
      )
      selectDateStart(format(selectedDate, 'YYYYMMDD'), locationId)
    } catch (error) {
      this.setState({ errorCreatingCateringDelivery: error.message })
    }
  }

  handleRemoveItemFromCart = orderItemId => {
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    const { removeItemFromCateringOrderCartStart } = this.props

    removeItemFromCateringOrderCartStart(orderItemId, storeId, locationId)
  }

  handleAddPastOrderToCart = order => {
    const { addPastOrderToCateringCart } = this.props
    const { storeId, locationId } = this.getStoreIdAndLocationId()
    addPastOrderToCateringCart(order, storeId, locationId)
  }

  handleDateChange = day => {
    const { errorCreatingCateringDelivery } = this.state
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (day >= today) {
      this.setState({ selectedDate: day })
    }

    if (errorCreatingCateringDelivery !== '') {
      this.setState({ errorCreatingCateringDelivery: '' })
    }

    this.setState({ showDatePicker: false })
  }

  handleDateInputChange = event => {
    const inputDate = event.target.value
    if (inputDate.length === 10) {
      const parsedDate = parse(inputDate, 'MM/DD/YYYY', new Date())
      if (!isNaN(parsedDate)) {
        this.setState({ selectedDate: parsedDate })
      }
    }
  }

  onSubmit = () => {
    const { orderCart, cateringStore } = this.props
    if (!isOrderMinimumHit(orderCart.itemSubTotal, cateringStore?.moneyMinimumInCents)) {
      this.setState({ checkoutErrorMessage: true })
      this.setState({
        minimumErrorMessage: 'Please add more items to your cart to meet the minimum order amount.',
      })
    } else {
      this.handleNext()
    }
  }

  formatDate = date => {
    return format(date, 'dddd, MMMM D')
  }

  toggleDatePicker = () => {
    this.setState(prevState => ({ showDatePicker: !prevState.showDatePicker }))
  }

  async componentDidMount() {
    window && window.scrollTo(0, 0)
    const {
      loadFavoriteStart,
      loadCateringMenuStart,
      loadCateringOrderCartStart,
      loadPastCateringOrdersStart,
      loadPopularMenuItems,
      loadCateringStoreStart,
      cateringStore,
      loadCateringDeliveryDateAndTimeStart,
      cateringDeliveryDate,
      cateringDeliveryTime,
    } = this.props

    const { selectedDate } = this.state

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    if (storeId && locationId) {
      loadCateringMenuStart(storeId, locationId)
      loadCateringOrderCartStart(storeId, locationId)
      loadPopularMenuItems()
      loadPastCateringOrdersStart(storeId)
      loadFavoriteStart()
      loadCateringDeliveryDateAndTimeStart(storeId, locationId)
      loadCateringStoreStart(storeId, locationId)
    }
    pushAnalyticsEvent('Catering Ordering', 'Pick Date & Time', undefined, storeId)

    const isDeliveryInThePast =
      cateringDeliveryDate &&
      parse(cateringDeliveryDate, 'MM/DD/YYYY', new Date()) < new Date().setHours(0, 0, 0, 0)

    if (cateringDeliveryDate && cateringDeliveryTime && !isDeliveryInThePast) {
      this.setState({
        selectedDate: parse(cateringDeliveryDate, 'MM/DD/YYYY', new Date()),
        time: cateringDeliveryTime,
      })
    } else if (cateringStore) {
      this.setState({
        selectedDate: '',
        time: '',
      })
    }

    if (selectedDate !== '') {
      this.setState({
        displayedMonth: new Date(selectedDate.getFullYear(), selectedDate.getMonth()),
      })
    }

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated,
      loadFavoriteStart,
      loadCateringMenuStart,
      loadCateringOrderCartStart,
      loadPastCateringOrdersStart,
      loadCateringStoreStart,
      loadCateringDeliveryDateAndTimeStart,
      cateringDeliveryDate,
      cateringDeliveryTime,
    } = this.props

    const { storeId, locationId } = this.getStoreIdAndLocationId()

    if (
      storeId !==
      Number(
        prevProps.match.params.storeId || locationId !== Number(prevProps.match.params.locationId),
      )
    ) {
      loadCateringMenuStart(storeId, locationId)
      loadCateringOrderCartStart(storeId, locationId)
      loadCateringStoreStart(storeId, locationId)
      loadCateringDeliveryDateAndTimeStart(storeId, locationId)
    }
    if (isAuthenticated !== prevProps.isAuthenticated) {
      loadPastCateringOrdersStart(storeId)
      loadFavoriteStart()
      loadCateringMenuStart(storeId, locationId)
      loadCateringOrderCartStart(storeId, locationId)
      loadCateringStoreStart(storeId, locationId)
      loadCateringDeliveryDateAndTimeStart(storeId, locationId)
    }

    const isDeliveryInThePast =
      cateringDeliveryDate &&
      parse(cateringDeliveryDate, 'MM/DD/YYYY', new Date()) < new Date().setHours(0, 0, 0, 0)

    if (
      (prevProps.cateringDeliveryDate !== cateringDeliveryDate ||
        prevProps.cateringDeliveryTime !== cateringDeliveryTime) &&
      !isDeliveryInThePast
    ) {
      this.setState({
        selectedDate: cateringDeliveryDate
          ? parse(cateringDeliveryDate, 'MM/DD/YYYY', new Date())
          : '',
        time: cateringDeliveryTime || '',
      })
    }
  }

  componentWillUnmount() {
    this.props.cancelEditingOrder()
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setDayPickerRef = node => {
    this.dayPickerRef = node
  }

  handleClickOutside = event => {
    if (this.dayPickerRef && !this.dayPickerRef.contains(event.target)) {
      this.setState({ showDatePicker: false })
    }
  }

  handleTimeChange = event => {
    const { errorCreatingCateringDelivery } = this.state
    this.setState({ time: event.target.value })
    if (errorCreatingCateringDelivery !== '') {
      this.setState({ errorCreatingCateringDelivery: '' })
    }
  }

  createTimeOptions = () => {
    const timeOptions = []

    for (let hour = 8; hour <= 20; hour++) {
      const hourString = hour <= 12 ? hour : hour - 12
      const amPm = hour < 12 ? 'AM' : 'PM'

      timeOptions.push({
        label: `${hourString}:00 ${amPm}`,
        value: `${hourString}:00 ${amPm}`,
      })

      if (hour !== 20) {
        timeOptions.push({
          label: `${hourString}:30 ${amPm}`,
          value: `${hourString}:30 ${amPm}`,
        })
      }
    }

    return timeOptions
  }

  handleAddItemButtonClick = () => {
    const { locationId, storeId } = this.getStoreIdAndLocationId()
    this.pushPlaceCateringOrder(storeId, locationId)
  }

  pushPlaceCateringOrder = (storeId, locationId) => {
    const { push } = this.props
    push(
      formatUrl(placeCateringOrderRoute.path, {
        locationId: locationId,
        storeId: storeId,
      }),
    )
  }

  handleMonthChange = newMonth => {
    this.setState({ displayedMonth: newMonth })
  }

  render() {
    const {
      isAuthenticated,
      isLoadingCateringDeliveryDateTime,
      classes,
      isCateringStoreLoading,
      cateringStore,
      orderCart,
    } = this.props
    const {
      selectedDate,
      showDatePicker,
      time,
      errorCreatingCateringDelivery,
      displayedMonth,
    } = this.state
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const deliveryDateAndTimeNotFilledOut = !selectedDate || time === ''

    const { locationId, storeId } = this.getStoreIdAndLocationId()

    const timeOptions = this.createTimeOptions(selectedDate)

    if (isLoadingCateringDeliveryDateTime || isCateringStoreLoading) {
      return <LayoutInnerPageSkeleton locationId={locationId} storeId={storeId} />
    }

    const disableCheckout =
      !isAuthenticated ||
      orderCart.orderItems.length === 0 ||
      cateringStore === undefined ||
      deliveryDateAndTimeNotFilledOut ||
      !isOrderMinimumHit(orderCart.itemSubTotal, cateringStore?.moneyMinimumInCents)

    return (
      <AuthedComponent isAuthenticated={isAuthenticated} locationId={locationId}>
        <LayoutInnerPage
          HeaderComponent={
            <HeaderInnerPage
              showBackButton
              backButtonRoute={formatUrl(placeCateringOrderRoute.path, { locationId, storeId })}
            >
              Back
            </HeaderInnerPage>
          }
          MainComponent={
            <Card>
              <Box marginBottom={4}>
                <Typography className={classes.enterDelivery}>
                  Enter delivery date & time
                </Typography>
                {!isCateringStoreLoading && (
                  <RestaurantHoursAndNotice
                    operationalHoursStart={cateringStore?.operationalHoursStart}
                    operationalHoursEnd={cateringStore?.operationalHoursEnd}
                    operationalDaysOfWeek={cateringStore?.operationalDaysOfWeek}
                    deliveryLeadTime={cateringStore?.cateringLeadTime}
                    isCatering={true}
                  />
                )}
              </Box>
              <Box component="section" marginBottom={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      size="small"
                      variant="outlined"
                      label="Delivery Date"
                      value={selectedDate !== '' ? this.formatDate(selectedDate) : ''}
                      onChange={this.handleDateInputChange}
                      onClick={this.toggleDatePicker}
                      readOnly
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        ),
                        style: { cursor: 'pointer' },
                        inputProps: { style: { pointerEvents: 'none' } },
                      }}
                    />
                  </Grid>
                  {showDatePicker && (
                    <div ref={this.setDayPickerRef} className={classes.dayPicker}>
                      <DayPicker
                        onDayClick={this.handleDateChange}
                        selectedDays={parse(this.state.selectedDate, 'MM/DD/YYYY', new Date())}
                        disabledDays={[
                          { before: today },
                          new Date(today.getFullYear(), 11, 24),
                          new Date(today.getFullYear(), 11, 25),
                          new Date(today.getFullYear(), 11, 31),
                          new Date(today.getFullYear() + 1, 0, 1),
                        ]}
                        fromMonth={new Date(today.getFullYear(), today.getMonth())}
                        month={displayedMonth}
                        onMonthChange={this.handleMonthChange}
                      />
                    </div>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      className={classes.timeField}
                      select
                      value={time}
                      label="Delivery Time"
                      variant="outlined"
                      size="small"
                      onChange={this.handleTimeChange}
                      SelectProps={{
                        MenuProps: {
                          style: { maxHeight: 370, marginTop: 8 },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        },
                      }}
                    >
                      {timeOptions.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.value}
                          autoFocus={
                            time !== '' ? option.label === time : option.label === '12:00 PM'
                          }
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <ButtonSubmit
                  color="primary"
                  fullWidth
                  disabled={disableCheckout}
                  onClick={this.onSubmit}
                  errorMessage={this.state.minimumErrorMessage}
                  variant="contained"
                  width={300}
                >
                  Continue to Checkout
                </ButtonSubmit>
              </Box>
              {errorCreatingCateringDelivery !== '' && (
                <Box display="flex" justifyContent="center" marginTop={'2em'}>
                  <Typography color="error" align="center">
                    {errorCreatingCateringDelivery}
                  </Typography>
                </Box>
              )}
            </Card>
          }
          SidebarComponent={
            <CateringOrderSidebar
              shouldDisplayNextButton={false}
              handleClose={this.handleClose}
              handleNext={this.handleNext}
              handleEditOrder={this.handleEditOrder}
              handleAddItemsToCart={this.handleAddItemsToCart}
              handleRemoveItemFromCart={this.handleRemoveItemFromCart}
              personalizeOrderAddMoreIsPrimary
              isPickingDateAndTime={true}
              deliveryDateAndTimeNotFilledOut={deliveryDateAndTimeNotFilledOut}
              personalizeOrderNextButtonIsHidden={true}
              handleAddItemButtonClick={this.handleAddItemButtonClick}
              showRestaurantLogo={true}
            />
          }
        />
      </AuthedComponent>
    )
  }
}

const LayoutInnerPageSkeleton = ({ locationId, storeId }) => {
  const headerComponent = (
    <HeaderInnerPage
      showBackButton
      backButtonRoute={formatUrl(placeCateringOrderRoute.path, { locationId, storeId })}
    >
      Back
    </HeaderInnerPage>
  )

  const mainComponentSkeleton = (
    <Card>
      <Box padding={2}>
        <Box display="flex" flexDirection="column" gap="1em">
          <Skeleton variant="rect" height={56} />
        </Box>
      </Box>
    </Card>
  )

  const sidebarComponentSkeleton = (
    <Card>
      <Box padding={2}>
        <Skeleton variant="rect" height={150} />
        <Skeleton variant="rect" height={150} style={{ marginTop: 16 }} />
      </Box>
    </Card>
  )

  return (
    <LayoutInnerPage
      HeaderComponent={headerComponent}
      MainComponent={mainComponentSkeleton}
      SidebarComponent={sidebarComponentSkeleton}
    />
  )
}

const mapStateToProps = state => {
  const { isMenuLoading, isOrderCartLoading, menu, menuItemId } = state.menu
  const { errorMessage: pastOrderError, isPastOrderLoading, pastOrders } = state.pastorder
  const {
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    menuItemForEdit,
    orderCart,
    orderCartError,
  } = state.cart
  const { isLoadingFavorites, isLoadingNewPopularMenuItems, isLoadingPopularItems } = state.favorite
  const { currentUser, isAuthenticated, isCurrentUserLoading } = state.user
  const popularItems = getMenuInfoForPopularItems(state)
  const newPopularItems = getMenuInfoForNewPopularItems(state)
  const deliveryError = state.delivery.errorLoading
  const { isWebpSupported } = state.browser
  const cateringDeliveryDate = selectFormattedCateringDeliveryDate(state)
  const cateringDeliveryTime = selectFormattedCateringDeliveryTime(state)
  const isLoadingCateringDeliveryDateTime = state.delivery.isLoadingCateringDeliveryDateTime
  const { isCateringStoreLoading, cateringStore } = state.catering

  return {
    currentUser,
    deliveryError,
    orderCartError,
    isAuthenticated,
    isCurrentUserLoading,
    isEditingFromMenu,
    isEditingOrder,
    isEditingPastOrder,
    isLoadingFavorites,
    isLoadingNewPopularMenuItems,
    isLoadingPopularItems,
    isMenuLoading,
    isOrderCartLoading,
    isPastOrderLoading,
    isWebpSupported,
    menu,
    menuItemForEdit,
    menuItemId,
    newPopularItems,
    pastOrderError,
    pastOrders,
    popularItems,
    orderCart,
    cateringDeliveryDate,
    cateringDeliveryTime,
    isLoadingCateringDeliveryDateTime,
    cateringStore,
    isCateringStoreLoading,
  }
}

const mapDispatchToProps = {
  addPastOrderToCateringCart,
  addItemToCateringCartStart,
  cancelEditingOrder,
  clearMenuItem,
  editCateringOrderInCart,
  loadEditOrder,
  loadFavoriteStart,
  loadCateringMenuStart,
  loadCateringOrderCartStart,
  loadPastCateringOrdersStart,
  loadPopularMenuItems,
  selectMenuItem,
  removeItemFromCateringOrderCartStart,
  loadCateringStoreStart,
  loadCateringDeliveryDateAndTimeStart,
  push,
  resetCheckoutError,
  selectDateStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PagePickCateringDeliveryDateTime))
