import React from 'react'
import { Divider, List, Box, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { themeConWeb } from '../../util/modernThemeConweb'
import AddManyLocationManagersDialog from './AddManyLocationManagersDialog'

const useStyles = makeStyles({
  itemName: { marginTop: '10px' },
  itemLabel: { color: themeConWeb.color.greyShade4, fontSize: '14px' },
  listItemBox: {
    marginLeft: '20px',
  },
  divider: { marginTop: '20px' },
})

const ListItem = ({ classes, title, label }) => {
  return (
    <Box mb={2} display="flex" alignItems="center">
      <Box className={classes.listItemBox}>
        <Typography className={classes.itemName}>{title}</Typography>
        <Typography className={classes.itemLabel} variant="body2">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

const LocationManagers = ({
  admins,
  handleAddManyLocationManagers,
  isCreatingManyLocationManagers,
  errorCreatingManyLocationManagers,
}) => {
  const classes = useStyles()
  return (
    <Box mb={2}>
      <Paper variant="outlined" elevation={0}>
        <Box padding={4}>
          <Box mt={3} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Location Managers</Typography>
            <AddManyLocationManagersDialog
              handleAddManyLocationManagers={handleAddManyLocationManagers}
              isCreatingManyLocationManagers={isCreatingManyLocationManagers}
              errorCreatingManyLocationManagers={errorCreatingManyLocationManagers}
            />
          </Box>
          <Box marginTop={3}>
            {admins.length > 0 && (
              <List>
                {admins.map((admin, index) => (
                  <>
                    <ListItem
                      key={index}
                      classes={classes}
                      title={`${admin.firstName} ${admin.lastName}`}
                      label={`${admin.username}`}
                    />
                    {index !== admins.length - 1 ? <Divider className={classes.divider} /> : null}
                  </>
                ))}
              </List>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default LocationManagers
