import React from 'react'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'
import { formatUrl, formatAverageExpectedOrders } from '../../util/formatUtils'
import { placeReserveOrderRoute } from '../../routes/routes'
import SkeletonLoadingDeliverySection from './SkeletonLoadingDeliverySection'
import DeliverySection from './DeliverySection'
import LoadingDeliverySectionError from './LoadingDeliverySectionError'
import ReserveHeader from './ReserveHeader'
import DeliverySectionCard from './DeliverySectionCard'
import RestaurantLogo from './RestaurantLogo'
import RestaurantName from './RestaurantName'
import ReserveRestaurantDeliveryDetail from './ReserveRestaurantDeliveryDetail'

const ReserveDeliverySection = ({
  reserveStores,
  isLoadingReserveStores,
  errorLoadingReserveStores,
  locationId,
  showSeeAll,
  deliveryRewardsTiers,
  isLoadingDeliveryRewardsTiers,
}) => {
  if (!isLoadingReserveStores && isEmpty(reserveStores)) {
    return null
  }

  return (
    <Box style={{ position: 'relative', paddingBottom: 4 }}>
      <DeliverySection
        HeaderComponent={() => (
          <ReserveHeader
            loading={isLoadingReserveStores && isLoadingDeliveryRewardsTiers}
            locationId={locationId}
            showSeeAll={showSeeAll}
          />
        )}
      >
        {errorLoadingReserveStores && !isLoadingReserveStores && !isLoadingDeliveryRewardsTiers && (
          <LoadingDeliverySectionError />
        )}
        {isLoadingReserveStores && isLoadingDeliveryRewardsTiers ? (
          <SkeletonLoadingDeliverySection />
        ) : (
          reserveStores.map(store => (
            <DeliverySectionCard
              key={store.storeId}
              isCardActive={true}
              url={formatUrl(placeReserveOrderRoute.path, {
                storeId: store.storeId,
                locationId: locationId,
              })}
              LogoComponent={() => (
                <RestaurantLogo restaurantName={store.restaurantName} logoLink={store.logoUrl} />
              )}
              HeaderComponent={() => (
                <RestaurantName name={store.restaurantName} favoritesCount={0} />
              )}
              SubheaderComponent={() => (
                <ReserveRestaurantDeliveryDetail
                  deliveryFeeInCents={store.deliveryFeeInCents}
                  leadTime={store.deliveryLeadTime}
                  averageOrders={formatAverageExpectedOrders(
                    store.averageOrdersPerDeliveryWithOrders,
                  )}
                  deliveryRewardsTiers={deliveryRewardsTiers}
                />
              )}
            />
          ))
        )}
      </DeliverySection>
    </Box>
  )
}

export default ReserveDeliverySection
