import React, { useState } from 'react'
import {
  Box,
  Card,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  Button,
  Drawer,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { connect, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import { isEmpty } from 'lodash'
import {
  selectCurrentLocationWithAddress,
  selectCurrentSavedLocation,
} from '../../redux/modules/selectors'
import { selectLocationPreferred } from '../../redux/selectors/locationSelectors'
import { selectUserTeam } from '../../redux/selectors/userTeamSelectors'
import { selectOfficeAccounts } from '../../redux/selectors/accountSelectors'
import { savedLocationsRoute, teamAccountRoute, locationManagerRoute } from '../../routes/routes'
import { formatAsMonthDayOrWeekday } from '../../util/dateUtils'
import { formatUrl } from '../../util/formatUtils'
import { hasTeam } from '../teams/teamUtils'
import { selectDateStart } from '../../redux/modules/delivery'
import { themeConWeb } from '../../util/modernThemeConweb'
import HomeFutureOrders from './HomeFutureOrders'
import AccountWidget from './AccountWidget'

const OfficeWidget = props => <AccountWidget title="Office" isManager {...props} />
const TeamWidget = props => <AccountWidget title="Team" isManager {...props} />

const useStyles = makeStyles(theme => ({
  paddedContent: {
    paddingLeft: '17px',
  },
  addressBox: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  locationBox: { paddingLeft: '17px' },
  locationTitle: {
    fontWeight: 500,
    fontSize: `${themeConWeb.fontSizes.base}`,
    letterSpacing: '1px',
    textTransform: 'capitalize',
    color: themeConWeb.color.greyShade3,
  },
  firstAddressLine: {
    fontSize: `${themeConWeb.fontSizes.base}`,
    fontWeight: 700,
    lineHeight: '22px',
  },
  addressLine: {
    fontSize: `${themeConWeb.fontSizes.baseMinus1}`,
  },
  changeSavedLocation: {
    cursor: 'pointer',
    fontWeight: 400,
    color: `${themeConWeb.color.dangerLight}`,
    '&:hover': {
      textDecoration: `underline ${themeConWeb.color.twoTint3}`,
    },
    margin: 0,
  },
  manageOffice: {
    fontSize: '12px',
    cursor: 'pointer',
    fontWeight: 400,
    color: `${themeConWeb.color.dangerLight}`,
    '&:hover': {
      textDecoration: `underline ${themeConWeb.color.twoTint3}`,
    },
  },
  closeIcon: {
    justifyContent: 'flex-end',
    paddingBottom: '10px',
  },
  mobileAddress: {
    textAlign: 'left',
    paddingBottom: '5px',
  },
  locationAndDate: { textAlign: 'left' },
  viewEditButton: { color: `${themeConWeb.color.two}` },
  changeButton: {
    color: `${themeConWeb.color.danger}`,
    textAlign: 'left',
    fontSize: `${themeConWeb.fontSizes.baseMinus1}`,
    fontWeight: 400,
  },
  fullScreenDrawer: {
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    padding: '5%',
  },
  [theme.breakpoints.up('sm')]: {
    addressBox: {
      alignItems: 'center',
      flexDirection: 'row',
      padding: '10px',
    },
    firstAddressLine: {
      fontSize: `${themeConWeb.fontSizes.base}`,
      fontWeight: 700,
      lineHeight: '22px',
    },
    changeSavedLocation: {
      fontSize: `${themeConWeb.fontSizes.baseMinus1}`,
      margin: '12px auto',
    },
    manageLocation: {
      fontSize: `${themeConWeb.fontSizes.baseMinus1}`,
      margin: '12px auto',
      marginLeft: '3px',
    },
  },
  [theme.breakpoints.up('md')]: {
    addressBox: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    changeSavedLocation: {
      fontSize: `${themeConWeb.fontSizes.baseMinus1}`,
      margin: '12px 0',
    },
  },
}))

export const fillAddressLines = address => {
  let { name, deliveryLocationName, street, city } = address
  return [name || deliveryLocationName, `${street}, ${city}`].filter(Boolean)
}

export const shouldShowLocationManagerLink = (locationId, locationUserManages) => {
  return locationUserManages?.locationId === locationId
}

const HomeSidebar = ({
  location,
  currentSavedLocation,
  selectDateStart,
  showCateringMobile,
  selectedDate,
  futureOrders,
  loadingFutureOrders,
  isAuthenticated,
  showUpcomingOrders,
  locationUserManages,
}) => {
  const classes = useStyles()
  const mobileView = useMediaQuery(theme => theme.breakpoints.between('xs', 'sm'))
  const locationId = location?.deliveryLocationId
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const userTeam = useSelector(selectUserTeam)
  const offices = useSelector(selectOfficeAccounts)
  const isPreferredLocation = useSelector(selectLocationPreferred)
  const isTeamManager = userTeam => userTeam?.team?.manager
  const isOfficeManager = office => office.isManager
  const isStandardOffice = office => office.preferredType === 'STANDARD'

  const formatTeamPath = accountId => {
    return formatUrl(teamAccountRoute.path, {
      accountId: `${accountId}`,
    })
  }

  const formatLocationManagerPath = locationId => {
    return formatUrl(locationManagerRoute.path, {
      locationId: `${locationId}`,
    })
  }

  const userTeamPath = () => {
    return formatTeamPath(userTeam?.team?.accountId)
  }

  const showLocationManagerLink = shouldShowLocationManagerLink(locationId, locationUserManages)

  if (mobileView) {
    return (
      <HomeSidebarMobileView
        location={location}
        currentSavedLocation={currentSavedLocation}
        showCateringMobile={showCateringMobile}
        selectDateStart={selectDateStart}
        selectedDate={selectedDate}
        locationId={locationId}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        classes={classes}
        futureOrders={futureOrders}
        loadingFutureOrders={loadingFutureOrders}
        offices={offices}
        isPreferredLocation={isPreferredLocation}
        userTeam={userTeam}
        isOfficeManager={isOfficeManager}
        formatTeamPath={formatTeamPath}
        isStandardOffice={isStandardOffice}
        isAuthenticated={isAuthenticated}
      />
    )
  }

  return (
    <Grid container style={{ flexDirection: 'column' }}>
      <Grid item className={classes.locationBox}>
        {location && (
          <LocationWidget
            classes={classes}
            currentSavedLocation={currentSavedLocation}
            location={location}
            locationManagerPath={formatLocationManagerPath(location.deliveryLocationId)}
            showLocationManagerLink={showLocationManagerLink}
          />
        )}
      </Grid>

      <Grid item className={classes.locationBox}>
        {isAuthenticated && (
          <Box paddingRight={4}>
            {isPreferredLocation && !hasTeam(userTeam) && !mobileView ? (
              <>
                {offices.map(office => (
                  <OfficeWidget
                    key={office.id}
                    name={office.name}
                    classes={classes}
                    showManageLink={!isStandardOffice(office) && isOfficeManager(office)}
                    manageLink={formatTeamPath(office.id)}
                  />
                ))}
              </>
            ) : null}

            {!isEmpty(userTeam.team) && !mobileView && (
              <>
                <TeamWidget
                  name={userTeam.team.name}
                  classes={classes}
                  manageLink={userTeamPath()}
                  showManageLink={isTeamManager(userTeam)}
                  members={userTeam.team.membersPreview}
                />
              </>
            )}
          </Box>
        )}
      </Grid>
      <Grid
        item
        style={{
          paddingLeft: '5px',
          paddingRight: '5px',
          paddingTop: '2em',
          paddingBottom: '2em',
        }}
      >
        <Divider fullWidth />
      </Grid>

      <Grid item className={classes.paddedContent}>
        {showUpcomingOrders && (
          <HomeFutureOrders
            futureOrders={futureOrders}
            locationId={locationId}
            loadingFutureOrders={loadingFutureOrders}
          />
        )}
      </Grid>
    </Grid>
  )
}

const LocationWidget = ({
  classes,
  currentSavedLocation,
  location,
  locationManagerPath,
  showLocationManagerLink,
}) => (
  <>
    <Typography className={classes.locationTitle}>Location</Typography>
    <Box>
      {location &&
        fillAddressLines(currentSavedLocation || location).map((line, i) => (
          <Typography
            key={`${line}-${i}`}
            className={cn(classes.addressLine, { [classes.firstAddressLine]: i === 0 })}
          >
            {line}
          </Typography>
        ))}
    </Box>
    <span className={classes.changeSavedLocation}>
      <Link href={savedLocationsRoute.path}>Change</Link>
    </span>
    {showLocationManagerLink && (
      <span className={classes.manageLocation}>
        <Link href={locationManagerPath}>| Manage</Link>
      </span>
    )}
  </>
)

const HomeSidebarMobileView = ({
  location,
  currentSavedLocation,
  selectedDate,
  locationId,
  open,
  handleClose,
  handleOpen,
  futureOrders,
  loadingFutureOrders,
  classes,
  offices,
  isPreferredLocation,
  userTeam,
  isStandardOffice,
  isOfficeManager,
  formatTeamPath,
}) => (
  <Card style={{ padding: '15px' }}>
    <Grid
      container
      spacing={1}
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item className={classes.locationAndDate}>
          <Box>
            <Typography>
              <span style={{ fontWeight: 800 }}>Location: </span>
              {currentSavedLocation?.deliveryLocationName || location.deliveryLocationName}
            </Typography>
          </Box>
          <Box>
            {offices.length > 0 &&
              offices.map(office => (
                <Typography key={office.id}>
                  <span style={{ fontWeight: 800 }}>Office:</span> {office.name}
                </Typography>
              ))}
          </Box>
          <Box>
            <Typography>
              <span style={{ fontWeight: 800 }}>Date: </span>{' '}
              {formatAsMonthDayOrWeekday(selectedDate)}
            </Typography>
          </Box>
          {futureOrders.length > 0 && (
            <Box>
              <Typography>
                <span style={{ fontWeight: 800 }}>Upcoming Orders: </span> {futureOrders.length}{' '}
                Orders
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item>
          <Button onClick={handleOpen} className={classes.viewEditButton}>
            View
          </Button>
        </Grid>
      </Grid>

      <Drawer open={open} anchor="top" classes={{ paper: classes.fullScreenDrawer }}>
        <Button className={classes.closeIcon} onClick={e => handleClose(e)}>
          <CloseIcon />
        </Button>
        <Typography variant="subtitle1">Delivery Details</Typography>
        <Typography className={classes.locationTitle}>Location</Typography>
        <Box display="flex" justifyContent="column" width="100%" flexDirection={'column'}>
          <Box className={classes.mobileAddress}>
            {location &&
              fillAddressLines(currentSavedLocation || location).map((line, i) => {
                return (
                  <>
                    <Typography
                      key={`${line}-${i}`}
                      className={cn(classes.addressLine, { [classes.firstAddressLine]: i === 0 })}
                    >
                      {line}
                    </Typography>
                  </>
                )
              })}
          </Box>
          <Link
            className={classes.changeButton}
            component={RouterLink}
            to={savedLocationsRoute.path}
          >
            Change
          </Link>
        </Box>
        <Box>
          {isPreferredLocation &&
            !hasTeam(userTeam) &&
            offices.length > 0 &&
            offices.map(office => (
              <OfficeWidget
                key={office.id}
                name={office.name}
                classes={classes}
                showManageLink={!isStandardOffice(office) && isOfficeManager(office)}
                manageLink={formatTeamPath(office.id)}
              />
            ))}
        </Box>
        <Box mt={3} />
        {futureOrders.length > 0 && (
          <HomeFutureOrders
            futureOrders={futureOrders}
            locationId={locationId}
            loadingFutureOrders={loadingFutureOrders}
          />
        )}
      </Drawer>
    </Grid>
  </Card>
)

const mapStateToProps = state => {
  const location = selectCurrentLocationWithAddress(state)
  const currentSavedLocation = selectCurrentSavedLocation(state)
  const { selectedDate } = state.delivery
  const { isAuthenticated } = state.user

  return {
    isAuthenticated,
    currentSavedLocation,
    location,
    selectedDate,
  }
}

const mapDispatchToProps = {
  selectDateStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeSidebar)
