import { Box, Link, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import currency from 'currency.js'
import { take } from 'lodash'
import React, { useState } from 'react'
import ButtonSubmit from '../common/ButtonSubmit'

import Favorite from '../community/Favorite'
import { Component as ComponentStyles } from './Menu'

/**
 *  PastOrders doesn't return an object structure that allows us to leverage the Menu component.
 *  This means we have to copy the whole component. To avoid copying the CSS we are importing the styles in from Menu
 */
export const MenuPastOrders = ({
  addPastOrderToCart,
  editPastOrder,
  pastOrderError,
  pastOrders,
  isSavingPastOrderToCart,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <ComponentStyles>
      <section>
        <Typography className="-categoryHeader" component="h2" gutterBottom variant="h5">
          Order Again
        </Typography>
        <Typography className="-categoryDescription" variant="body1"></Typography>
        <ul>
          {take(pastOrders, isExpanded ? pastOrders.length : 1).map(pastOrder => (
            <li className="-categoryItem" key={pastOrder.orderId}>
              {pastOrder.orderItems.map(item => (
                <div key={item.hash} style={{ position: 'relative' }}>
                  <Favorite
                    className="-categoryFavoritePastOrder"
                    id={item.menuItemId}
                    parentComponentName="Menu"
                  />
                  <button
                    className="-categoryPrimaryContents"
                    key={item.id}
                    onClick={() => {
                      editPastOrder(item.hash, null, true)
                    }}
                  >
                    <div className="-categoryPrimaryContent">
                      <Link className="-categoryItemTitle" color="textPrimary" variant="h6">
                        {item.displayText}
                      </Link>
                      <Typography className="-categoryItemPrice" variant="h6">
                        {currency(item.fullSubTotal).format()}
                      </Typography>
                    </div>
                    {item.modifiers.map(modifier => (
                      <div key={modifier.questionId}>
                        {modifier.answers.map((answer, index) => (
                          <Typography className="-itemDescription" key={index} variant="body1">
                            {answer.displayText}
                          </Typography>
                        ))}
                      </div>
                    ))}
                  </button>
                </div>
              ))}
              <Box display="flex" justifyContent="flex-end">
                <ButtonSubmit
                  variant="none"
                  submitting={isSavingPastOrderToCart}
                  onClick={() => addPastOrderToCart(pastOrder)}
                  startIcon={<Add />}
                >
                  Add To Cart
                </ButtonSubmit>
              </Box>
            </li>
          ))}
        </ul>
        {pastOrders?.length > 1 && (
          <Box display="flex" justifyContent="flex-end">
            <Link onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? `Show Fewer` : `Show more`}
            </Link>
          </Box>
        )}
        {{ pastOrderError } && (
          <Typography color="error" component="p" variant="h6">
            {pastOrderError}
          </Typography>
        )}
      </section>
    </ComponentStyles>
  )
}
export default MenuPastOrders
