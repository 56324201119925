import React, { useState } from 'react'
import { Button, Menu, MenuItem, Typography } from '@material-ui/core'
import { themeConWeb } from '../../util/modernThemeConweb'
import SortableTable from '../common/SortableTable'
import { ROWS_PER_PAGE } from '../../util/constants'
import {
  estimatedEmployeeCountDisplayText,
  officeLocationRelationshipTypeDisplayText,
} from './utils'
import HeaderTableLocationOffices from './HeaderTableLocationOffices'
import LocationManagerEditOfficeDialog from './LocationManagerEditOfficeDialog'
import LocationManagerRemoveOfficeDialog from './LocationManagerRemoveOfficeDialog'

const ExternalManagers = ({ externalManagers }) => {
  if (!externalManagers || externalManagers.length === 0) {
    return <Typography>None</Typography>
  }
  const firstManager = externalManagers[0].username
  const remainingCount = externalManagers.length - 1
  return remainingCount > 0 ? (
    <>
      <Typography style={{ fontWeight: 500 }}>{firstManager}</Typography>
      <Typography style={{ color: themeConWeb.color.greyShade4, fontSize: '14px' }}>
        +{remainingCount} others
      </Typography>
    </>
  ) : (
    <Typography style={{ fontWeight: 500 }}>{firstManager}</Typography>
  )
}

const TableLocationOffices = ({
  locationOffices,
  filteredOffices,
  loadingTable,
  pageOffices,
  setPageOffices,
  handleEditOffice,
  isEditingOffice,
  errorEditingOffice,
  handleRemoveOffice,
  isRemovingOffice,
  errorRemovingOffice,
  clearOfficeErrors,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openEditOfficeDialog, setOpenEditOfficeDialog] = useState(false)
  const [officeOfActionButton, setOfficeOfActionButton] = useState(null)
  const [openRemoveOfficeDialog, setOpenRemoveOfficeDialog] = useState(false)

  const handleClickActions = (event, office) => {
    setAnchorEl(event.currentTarget)
    setOfficeOfActionButton(office)
  }

  const handleCloseActions = () => {
    setAnchorEl(null)
    setOfficeOfActionButton(null)
  }

  const handleOpenEditOfficeDialog = () => {
    setOpenEditOfficeDialog(true)
    setAnchorEl(null)
  }

  const handleOpenRemoveOfficeDialog = () => {
    setOpenRemoveOfficeDialog(true)
    setAnchorEl(null)
  }

  const handleCloseEditOfficeDialog = () => {
    setOpenEditOfficeDialog(false)
    clearOfficeErrors()
  }

  const handleCloseRemoveOfficeDialog = () => {
    setOpenRemoveOfficeDialog(false)
    clearOfficeErrors()
  }

  const columns = [
    {
      id: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      id: 'estimatedEmployeeCount',
      label: 'Members',
      sortable: true,
    },
    {
      id: 'externalManagers',
      label: 'Admins',
      sortable: false,
    },
    {
      id: 'officeLocationRelationshipType',
      label: 'Type',
      sortable: true,
    },
    {
      id: '',
      label: '',
      sortable: false,
    },
  ]

  const rows = filteredOffices.slice(
    pageOffices * ROWS_PER_PAGE,
    pageOffices * ROWS_PER_PAGE + ROWS_PER_PAGE,
  )
  const rowCells = rows.map(office => {
    return {
      cells: [
        {
          Component: (
            <>
              <Typography style={{ fontWeight: 500 }}>{office.name}</Typography>
              <Typography style={{ color: themeConWeb.color.greyShade4, fontSize: '14px' }}>
                @{office.emailDomain}
              </Typography>
            </>
          ),
        },
        {
          Component: (
            <Typography>
              {estimatedEmployeeCountDisplayText(office.estimatedEmployeeCount)}
            </Typography>
          ),
        },
        {
          Component: <ExternalManagers externalManagers={office.externalManagers} />,
        },
        {
          Component: (
            <Typography>
              {officeLocationRelationshipTypeDisplayText(office.officeLocationRelationshipType)}
            </Typography>
          ),
        },
        {
          Component: (
            <>
              <Button variant="contained" onClick={event => handleClickActions(event, office)}>
                Actions
              </Button>
            </>
          ),
        },
      ],
    }
  })

  return (
    <>
      <SortableTable
        data={locationOffices}
        filteredData={filteredOffices}
        rowCells={rowCells}
        page={pageOffices}
        setPage={setPageOffices}
        loading={loadingTable}
        HeaderComponent={<HeaderTableLocationOffices columns={columns} />}
      />
      {officeOfActionButton && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseActions}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleOpenEditOfficeDialog} style={{ minWidth: 160, minHeight: 50 }}>
            Edit
          </MenuItem>
          <MenuItem onClick={handleOpenRemoveOfficeDialog} style={{ minWidth: 160, minHeight: 50 }}>
            Remove
          </MenuItem>
        </Menu>
      )}
      {openEditOfficeDialog && officeOfActionButton && (
        <LocationManagerEditOfficeDialog
          handleEditOffice={handleEditOffice}
          office={officeOfActionButton}
          isEditingOffice={isEditingOffice}
          errorEditingOffice={errorEditingOffice}
          handleCloseEditOfficeDialog={handleCloseEditOfficeDialog}
        />
      )}
      {openRemoveOfficeDialog && officeOfActionButton && (
        <LocationManagerRemoveOfficeDialog
          handleRemoveOffice={handleRemoveOffice}
          office={officeOfActionButton}
          isRemovingOffice={isRemovingOffice}
          errorRemovingOffice={errorRemovingOffice}
          handleCloseRemoveOfficeDialog={handleCloseRemoveOfficeDialog}
        />
      )}
    </>
  )
}

export default TableLocationOffices
