import { Box, useMediaQuery } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { format } from 'date-fns'
import scriptLoader from 'react-async-script-loader'
import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import LayoutInnerHomePage from '../layouts/LayoutInnerHomePage'
import MobileWidgetSkeleton from '../components/home/MobileWidgetSkeleton'
import FeedbackLink from '../components/home/FeedbackLink'
import { selectDateStart } from '../redux/modules/delivery'
import HomeDialogs from '../components/home/HomeDialogs'
import HomeSnackbars from '../components/home/HomeSnackbars'
import HomeUnlockPreferredPricing from '../components/home/HomeUnlockPreferredPricing'
import HomeSidebar from '../components/home/HomeSidebar'
import CateringDeliverySection from '../components/home/CateringDeliverySection'
import { loadDeliveryScheduleStart, loadDeliveryRewardsStart } from '../redux/modules/delivery'
import { loadCateringStoresStart } from '../redux/modules/cateringStores'
import { addFavoritesForMenusStart } from '../redux/modules/favorite'
import { loadFutureOrdersStart } from '../redux/modules/orderDetail'
import { selectCurrentLocationWithAddress } from '../redux/modules/selectors'
import { selectDeliveries } from '../redux/selectors/deliverySelectors'
import { loadOfficesStart } from '../redux/modules/offices'
import { selectCateringStores } from '../redux/selectors/cateringStoreSelectors'
import { getFriendsOrdersStart } from '../redux/modules/friends'
import { isSelectedDeliveryDateActive } from '../redux/selectors/deliverySelectors'
import { selectOfficeAccounts } from '../redux/selectors/accountSelectors'

function withMediaQuery(Component) {
  return function WrappedComponent(props) {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
    return <Component {...props} isDesktop={isDesktop} />
  }
}

class PageCateringHome extends Component {
  componentDidMount() {
    let {
      selectedDate,
      match: {
        params: { locationId },
      },
      loadDeliveryRewardsStart,
    } = this.props

    this.fetchCateringStores(locationId)
    loadDeliveryRewardsStart(locationId, format(selectedDate, 'YYYYMMDD'))
    window && window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props
    let {
      match: {
        params: { locationId = 0 },
      },
    } = this.props
    locationId = Number(locationId)
    if (Number(prevProps.match.params.locationId) !== locationId) {
      this.loadCateringStoresStart(locationId)
      this.fetchOffices(locationId)
    }
    if (prevProps.isAuthenticated === false && isAuthenticated === true) {
      this.fetchFriends(locationId)
    }
  }

  fetchCateringStores = locationId => {
    const { loadCateringStoresStart } = this.props
    loadCateringStoresStart(locationId, false)
  }

  render() {
    const {
      isLoadingDeliveries,
      isScriptLoadSucceed,
      isScriptLoaded,
      isDesktop,
      cateringStores,
      selectDateStart,
      selectedDate,
      isLoadingCateringStores,
      errorLoadingCateringStores,
      loadingFutureOrders,
      offices,
      isLoadingLocationUserManages,
      locationUserManages,
    } = this.props
    let {
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)

    let futureOrders = []

    return (
      <>
        <LayoutInnerHomePage
          HeaderComponent={
            <>
              <MobileWidgetSkeleton
                isDesktop={isDesktop}
                loading={isLoadingDeliveries || loadingFutureOrders || isLoadingLocationUserManages}
                WidgetComponent={() => (
                  <HomeSidebar
                    selectDateStart={selectDateStart}
                    locationId={locationId}
                    selectedDate={selectedDate}
                    futureOrders={futureOrders}
                    loadingFutureOrders={null}
                    offices={offices}
                    showUpcomingOrders={false}
                    isLoadingLocationUserManages={isLoadingLocationUserManages}
                    locationUserManages={locationUserManages}
                  />
                )}
              />
            </>
          }
          MainComponent={
            <>
              <CateringDeliverySection
                cateringStores={cateringStores}
                locationId={locationId}
                isLoadingCateringStores={isLoadingCateringStores}
                errorLoadingCateringStores={errorLoadingCateringStores}
                showSeeAll={false}
              />

              {!isLoadingDeliveries && (
                <Box className="center-xs" marginTop={4} marginBottom={4}>
                  <FeedbackLink />
                </Box>
              )}
            </>
          }
          SidebarComponent={
            <HomeSidebar
              selectDateStart={selectDateStart}
              locationId={locationId}
              selectedDate={selectedDate}
              futureOrders={futureOrders}
              loadingFutureOrders={loadingFutureOrders}
              showUpcomingOrders={false}
              isLoadingLocationUserManages={isLoadingLocationUserManages}
              locationUserManages={locationUserManages}
            />
          }
          RightSidebarComponents={isDesktop ? [() => <HomeUnlockPreferredPricing />] : []}
        />
        <HomeDialogs
          locationId={locationId}
          isScriptLoaded={isScriptLoaded}
          isScriptLoadSucceed={isScriptLoadSucceed}
        />
        <HomeSnackbars />
      </>
    )
  }
}

const mapStateToProps = state => {
  const deliveries = selectDeliveries(state)
  const {
    isLoadingDeliveries,
    selectedDate,
    errorLoading,
    rewards,
    isLoadingRewards,
  } = state.delivery
  const { isAuthenticated } = state.user
  const { locationUserManages, isLoadingLocationUserManages } = state.locationManager
  const location = selectCurrentLocationWithAddress(state)
  const { futureOrders, loadingFutureOrders } = state.orderDetail
  const offices = selectOfficeAccounts(state)
  const cateringStores = selectCateringStores(state)
  const { friendsOrdered } = state.friends
  const { isLoadingCateringStores, errorLoadingCateringStores } = state.cateringStores
  const { location: { activationDate } = {} } = state.location
  const isSelectedDayActive = isSelectedDeliveryDateActive(state)
  const { isWebpSupported } = state.browser

  return {
    deliveries,
    isAuthenticated,
    isLoadingDeliveries,
    friendsOrdered,
    location,
    futureOrders,
    loadingFutureOrders,
    selectedDate,
    offices,
    cateringStores,
    activationDate,
    errorLoading,
    isSelectedDayActive,
    isWebpSupported,
    isLoadingCateringStores,
    errorLoadingCateringStores,
    rewards,
    isLoadingRewards,
    locationUserManages,
    isLoadingLocationUserManages,
  }
}

const mapDispatchToProps = {
  addFavoritesForMenusStart,
  getFriendsOrdersStart,
  loadDeliveryScheduleStart,
  loadDeliveryRewardsStart,
  loadCateringStoresStart,
  loadOfficesStart,
  selectDateStart,
  loadFutureOrdersStart,
}

export default scriptLoader(MAPS_SCRIPT_URL)(
  connect(mapStateToProps, mapDispatchToProps)(withMediaQuery(PageCateringHome)),
)
