import { Box, useMediaQuery } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { format } from 'date-fns'
import scriptLoader from 'react-async-script-loader'
import { MAPS_SCRIPT_URL } from '../components/location-search/LocationSearchBox'
import LayoutInnerHomePage from '../layouts/LayoutInnerHomePage'
import MobileWidgetSkeleton from '../components/home/MobileWidgetSkeleton'
import FeedbackLink from '../components/home/FeedbackLink'
import { selectDateStart } from '../redux/modules/delivery'
import HomeDialogs from '../components/home/HomeDialogs'
import HomeSnackbars from '../components/home/HomeSnackbars'
import HomeUnlockPreferredPricing from '../components/home/HomeUnlockPreferredPricing'
import HomeSidebar from '../components/home/HomeSidebar'
import ReserveDeliverySection from '../components/home/ReserveDeliverySection'
import { loadDeliveryScheduleStart, loadDeliveryRewardsStart } from '../redux/modules/delivery'
import { loadReserveStoresStart } from '../redux/modules/reserveStores'
import { addFavoritesForMenusStart } from '../redux/modules/favorite'
import { selectCurrentLocationWithAddress } from '../redux/modules/selectors'
import { selectDeliveries } from '../redux/selectors/deliverySelectors'
import { loadOfficesStart } from '../redux/modules/offices'
import { getFriendsOrdersStart } from '../redux/modules/friends'
import { isSelectedDeliveryDateActive } from '../redux/selectors/deliverySelectors'
import { selectOfficeAccounts } from '../redux/selectors/accountSelectors'
import { loadDeliveryRewardsTiersStart } from '../redux/modules/deliveryDropoffRewards'

function withMediaQuery(Component) {
  return function WrappedComponent(props) {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
    return <Component {...props} isDesktop={isDesktop} />
  }
}

class PageReserveHome extends Component {
  componentDidMount() {
    let {
      selectedDate,
      match: {
        params: { locationId },
      },
      loadDeliveryRewardsStart,
    } = this.props

    this.fetchReserveStores(locationId)
    this.fetchRewardsTiers()
    loadDeliveryRewardsStart(locationId, format(selectedDate, 'YYYYMMDD'))
    window && window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props
    let {
      match: {
        params: { locationId = 0 },
      },
    } = this.props
    locationId = Number(locationId)
    if (Number(prevProps.match.params.locationId) !== locationId) {
      this.fetchReserveStores(locationId)
      this.fetchRewardsTiers()
      this.fetchOffices(locationId)
    }
    if (prevProps.isAuthenticated === false && isAuthenticated === true) {
      this.fetchFriends(locationId)
    }
  }

  fetchReserveStores = locationId => {
    const { loadReserveStoresStart } = this.props
    loadReserveStoresStart(locationId, false)
  }

  fetchRewardsTiers = () => {
    const { loadDeliveryRewardsTiersStart } = this.props
    loadDeliveryRewardsTiersStart()
  }

  render() {
    const {
      isLoadingDeliveries,
      isScriptLoadSucceed,
      isScriptLoaded,
      isDesktop,
      selectDateStart,
      selectedDate,
      reserveStores,
      isLoadingReserveStores,
      errorLoadingReserveStores,
      offices,
      deliveryRewardsTiers,
      isLoadingDeliveryRewardsTiers,
      isLoadingLocationUserManages,
      locationUserManages,
      loadingFutureOrders,
    } = this.props
    let {
      match: {
        params: { locationId },
      },
    } = this.props
    locationId = Number(locationId)

    let futureOrders = []

    return (
      <>
        <LayoutInnerHomePage
          HeaderComponent={
            <>
              <MobileWidgetSkeleton
                isDesktop={isDesktop}
                loading={isLoadingDeliveries || loadingFutureOrders || isLoadingLocationUserManages}
                WidgetComponent={() => (
                  <HomeSidebar
                    selectDateStart={selectDateStart}
                    locationId={locationId}
                    selectedDate={selectedDate}
                    futureOrders={futureOrders}
                    loadingFutureOrders={loadingFutureOrders}
                    offices={offices}
                    showUpcomingOrders={false}
                    locationUserManages={locationUserManages}
                    isLoadingLocationUserManages={isLoadingLocationUserManages}
                  />
                )}
              />
            </>
          }
          MainComponent={
            <>
              <ReserveDeliverySection
                reserveStores={reserveStores}
                isLoadingReserveStores={isLoadingReserveStores}
                errorLoadingReserveStores={errorLoadingReserveStores}
                locationId={locationId}
                showSeeAll={false}
                deliveryRewardsTiers={deliveryRewardsTiers}
                isLoadingDeliveryRewardsTiers={isLoadingDeliveryRewardsTiers}
              />

              {!isLoadingDeliveries && (
                <Box className="center-xs" marginTop={4} marginBottom={4}>
                  <FeedbackLink />
                </Box>
              )}
            </>
          }
          SidebarComponent={
            <HomeSidebar
              selectDateStart={selectDateStart}
              locationId={locationId}
              selectedDate={selectedDate}
              futureOrders={futureOrders}
              loadingFutureOrders={loadingFutureOrders}
              showUpcomingOrders={false}
              locationUserManages={locationUserManages}
              isLoadingLocationUserManages={isLoadingLocationUserManages}
            />
          }
          RightSidebarComponents={isDesktop ? [() => <HomeUnlockPreferredPricing />] : []}
        />
        <HomeDialogs
          locationId={locationId}
          isScriptLoaded={isScriptLoaded}
          isScriptLoadSucceed={isScriptLoadSucceed}
        />
        <HomeSnackbars />
      </>
    )
  }
}

const mapStateToProps = state => {
  const deliveries = selectDeliveries(state)
  const {
    isLoadingDeliveries,
    selectedDate,
    errorLoading,
    rewards,
    isLoadingRewards,
  } = state.delivery
  const { isLoadingDeliveryRewardsTiers, deliveryRewardsTiers } = state.deliveryDropoffRewards
  const { locationUserManages, isLoadingLocationUserManages } = state.locationManager
  const { isAuthenticated } = state.user
  const location = selectCurrentLocationWithAddress(state)
  const { futureOrders, loadingFutureOrders } = state.orderDetail
  const offices = selectOfficeAccounts(state)
  const { reserveStores, isLoadingReserveStores, errorLoadingReserveStores } = state.reserveStores
  const { friendsOrdered } = state.friends
  const { location: { activationDate } = {} } = state.location
  const isSelectedDayActive = isSelectedDeliveryDateActive(state)
  const { isWebpSupported } = state.browser

  return {
    deliveries,
    isAuthenticated,
    isLoadingDeliveries,
    friendsOrdered,
    location,
    futureOrders,
    loadingFutureOrders,
    selectedDate,
    offices,
    reserveStores,
    activationDate,
    errorLoading,
    isSelectedDayActive,
    isWebpSupported,
    isLoadingReserveStores,
    errorLoadingReserveStores,
    rewards,
    isLoadingRewards,
    isLoadingDeliveryRewardsTiers,
    deliveryRewardsTiers,
    locationUserManages,
    isLoadingLocationUserManages,
  }
}

const mapDispatchToProps = {
  addFavoritesForMenusStart,
  getFriendsOrdersStart,
  loadDeliveryScheduleStart,
  loadDeliveryRewardsStart,
  loadReserveStoresStart,
  loadOfficesStart,
  selectDateStart,
  loadDeliveryRewardsTiersStart,
}

export default scriptLoader(MAPS_SCRIPT_URL)(
  connect(mapStateToProps, mapDispatchToProps)(withMediaQuery(PageReserveHome)),
)
