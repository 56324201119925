import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { array, object, string } from 'yup'
import ButtonSubmit from '../common/ButtonSubmit'
import { estimatedEmployeeCountOptions, officeLocationRelationshipTypeOptions } from './utils'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/

const isAlreadyExternalManager = externalManagers => email => {
  return externalManagers.some(manager => manager.username === email)
}

const createSchema = externalManagers =>
  object().shape({
    name: string().required("Please enter the office's name."),
    externalManagerEmails: array().of(
      string()
        .matches(emailRegex, ({ value }) => `Invalid email: ${value}`)
        .test(
          'unique-email',
          ({ value }) => `${value} is already an admin.`,
          value => (value ? !isAlreadyExternalManager(externalManagers)(value) : true),
        ),
    ),
  })

const LocationManagerEditOfficeDialog = ({
  handleEditOffice,
  office,
  isEditingOffice,
  errorEditingOffice,
  handleCloseEditOfficeDialog,
}) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <Dialog open onClose={handleCloseEditOfficeDialog}>
      <DialogTitle disableTypography>
        <Typography variant="h6" gutterBottom>
          <strong>Edit {office.name}</strong>
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          officeId: office.id,
          estimatedEmployeeCount: office.estimatedEmployeeCount,
          officeLocationRelationshipType: office.officeLocationRelationshipType,
          name: office.name,
          externalManagerEmails: [],
        }}
        onSubmit={handleEditOffice}
        validationSchema={createSchema(office.externalManagers)}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2} alignItems="center">
                {/* Name Field */}
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: '500' }}>Name:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <TextField
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        fullWidth
                        id="name"
                        name="name"
                        placeholder="Name"
                        margin="dense"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                        inputProps={{ maxLength: 60 }}
                      />
                    </Grid>
                    <Grid item style={{ marginTop: '-0.5em' }}>
                      <Typography variant="caption" color="textSecondary">
                        {values.name.length}/60 characters
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Size Dropdown */}
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: '500' }}>Size:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth margin="dense">
                    <Select
                      id="estimatedEmployeeCount"
                      name="estimatedEmployeeCount"
                      value={values.estimatedEmployeeCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      displayEmpty
                    >
                      {Object.keys(estimatedEmployeeCountOptions).map(key => (
                        <MenuItem key={key} value={key}>
                          {estimatedEmployeeCountOptions[key]} people
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Type Dropdown */}
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: '500' }}>Type:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth margin="dense">
                    <Select
                      id="officeLocationRelationshipType"
                      name="officeLocationRelationshipType"
                      value={values.officeLocationRelationshipType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      displayEmpty
                    >
                      {Object.keys(officeLocationRelationshipTypeOptions).map(key => (
                        <MenuItem key={key} value={key}>
                          {officeLocationRelationshipTypeOptions[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* External Managers Field */}
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: '500' }}>Admins:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                        border="1px solid #ccc"
                        borderRadius="4px"
                        padding="8px"
                      >
                        {/* Static chips for office.externalManagers */}
                        {office.externalManagers.map((manager, index) => (
                          <Chip
                            key={index}
                            label={manager.username}
                            style={{ margin: '4px' }}
                            color="default"
                            clickable={false}
                            onDelete={null}
                          />
                        ))}

                        {/* Dynamic chips for values.externalManagerEmails */}
                        {values.externalManagerEmails.map((email, index) => (
                          <Chip
                            key={index + office.externalManagers.length}
                            label={email}
                            onDelete={() => {
                              const updatedEmails = values.externalManagerEmails.filter(
                                e => e !== email,
                              )
                              setFieldValue('externalManagerEmails', updatedEmails)
                            }}
                            style={{ margin: '4px' }}
                          />
                        ))}

                        <TextField
                          value={inputValue}
                          onChange={e => setInputValue(e.target.value)}
                          onKeyDown={e => {
                            if (e.key === 'Backspace' && !inputValue) {
                              const updatedEmails = values.externalManagerEmails.slice(0, -1)
                              setFieldValue('externalManagerEmails', updatedEmails)
                            }
                          }}
                          onKeyPress={e => {
                            if (e.key === ',' || e.key === 'Enter') {
                              e.preventDefault()
                              const trimmedInput = inputValue.trim()
                              if (trimmedInput) {
                                setFieldValue('externalManagerEmails', [
                                  ...values.externalManagerEmails,
                                  trimmedInput,
                                ])
                                setInputValue('')
                              }
                            }
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          style={{ flex: 1, minWidth: '120px' }}
                        />
                      </Box>
                    </Grid>

                    <Grid item style={{ marginTop: '-0.5em' }}>
                      <Typography variant="caption" color="textSecondary">
                        Type email & press enter or a comma
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {errors.externalManagerEmails && touched.externalManagerEmails && (
                  <Typography color="error">{errors.externalManagerEmails}</Typography>
                )}
                {errorEditingOffice && <Typography color="error">{errorEditingOffice}</Typography>}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={handleCloseEditOfficeDialog}>
                Cancel
              </Button>
              <ButtonSubmit variant="text" submitting={isEditingOffice}>
                Save
              </ButtonSubmit>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default LocationManagerEditOfficeDialog
