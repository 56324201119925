import { getReserveStoresForLocation } from '../../api/api'
import { createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const RESERVE_STORES_SET = 'foodsby/reserveStores/RESERVE_STORES_SET'

export const setReserveStores = createAsyncAction(RESERVE_STORES_SET)

export const loadReserveStoresStart = (locationId, isHome) => {
  return dispatch => {
    dispatch(setReserveStores(getReserveStoresForLocation(locationId, isHome)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [PENDING(RESERVE_STORES_SET)]: state => {
    return {
      ...state,
      isLoadingReserveStores: true,
    }
  },
  [FULFILLED(RESERVE_STORES_SET)]: (state, action) => {
    return {
      ...state,
      reserveStores: action.payload ?? [],
      errorLoadingReserveStores: undefined,
      isLoadingReserveStores: false,
    }
  },
  [REJECTED(RESERVE_STORES_SET)]: (state, action) => {
    return {
      ...state,
      reserveStores: [],
      errorLoadingReserveStores: action.payload,
      isLoadingReserveStores: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  reserveStores: [],
  isLoadingReserveStores: false,
  errorLoadingReserveStores: undefined,
}

export default function reserveStores(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
