import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import React from 'react'
import ButtonSubmit from '../common/ButtonSubmit'

const LocationManagerRemoveOfficeDialog = ({
  handleRemoveOffice,
  office,
  isRemovingOffice,
  errorRemovingOffice,
  handleCloseRemoveOfficeDialog,
}) => {
  return (
    <Dialog open onClose={handleCloseRemoveOfficeDialog}>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          <strong>Are you sure you want to remove {office.name}?</strong>
        </Typography>
        {errorRemovingOffice && (
          <Box marginTop={'1em'}>
            <Typography color="error">{errorRemovingOffice}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCloseRemoveOfficeDialog}>
          Cancel
        </Button>
        <ButtonSubmit
          variant="text"
          submitting={isRemovingOffice}
          onClick={() => handleRemoveOffice(office.id)}
        >
          Remove
        </ButtonSubmit>
      </DialogActions>
    </Dialog>
  )
}

export default LocationManagerRemoveOfficeDialog
